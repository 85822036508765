<template>
  <div>
    <StreamBarcodeReader
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>
  </div>
</template>

<script>
// import { BarcodeDetectorPolyfill } from "barcode-detector-polyfill";
// import BarcodeScanner from "vue-barcode-scanner";
import { StreamBarcodeReader } from "vue-barcode-reader";
import { mapActions } from "vuex";
// try {
//   new window["BarcodeDetector"]().getContext("2d");
// } catch {
//   window["BarcodeDetector"] = BarcodeDetectorPolyfill;
// }

export default {
  layout: "empty",
  components: { StreamBarcodeReader },
  data() {
    return {
      camera: null,
      barcodes: [],
      busy: false,
    };
  },
  async created() {
    const data = await this.findProducts();
    console.log("data", data);
    this.productos = data.data;
  },

  mounted() {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream) => (this.camera = stream));

    // this.crearStock({
    //   producto: "TEST",
    //   cantidad: 1,
    //   tipo: "baja",
    //   motivo: "venta roxy",
    // });
  },
  methods: {
    ...mapActions("products", { findProducts: "find" }),
    ...mapActions("impacto-stock", { crearStock: "create" }),
    async onLoaded(res) {
      console.log(res);
    },
    async onDecode(isbn) {
      console.log(isbn);
      // if (this.busy) return;
      // try {
      //   this.busy = true;
      //   const producto = this.productos.find((product) => product.upc === isbn);
      //   if (!producto) {
      //     this.$toast.error("No se encontró el producto con el ISBN: " + isbn);
      //   } else {
      //     this.$toast.success("Se encontró el producto con el ISBN: " + isbn);
      //     await this.crearStock({
      //       producto: producto._id,
      //       cantidad: 1,
      //       tipo: "baja",
      //       motivo: "venta roxy",
      //     });
      //   }
      // } catch (e) {
      //   console.log(e);
      //   this.$toast.error("Intentar nuevamente");
      // }
      // const self = this;
      // setTimeout(() => {
      //   self.busy = false;
      // }, 1000);
      // Crear baja de stock en impacto-stock
    },
  },
};
</script>

<style>
</style>